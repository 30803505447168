import '../css/app.css';
import './Polyfills/ObjectGroupBy';

import { createInertiaApp, usePage } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { getActiveLanguage, i18nVue, loadLanguageAsync } from 'laravel-vue-i18n';
import { createApp, h, watch } from 'vue';
import VueTippy from 'vue-tippy';

import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { initEcho } from './echo';
import emitter from './emitter';
import { initSW } from './Support/WebPushNotifications';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Loopa';

createInertiaApp({
    title: title => `${title} - ${appName}`,
    progress: {
        color: '#10b981',
    },
    resolve: name =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const auth = props?.initialPage?.props?.auth;
        const user = auth?.user;
        const guard = auth?.guard;

        initEcho();

        if (import.meta.env.VITE_APP_ENV === 'production') {
            Sentry.init({
                dsn: 'https://9894bc3c224cc7fd54409926d4e8061e@o4506423098540032.ingest.sentry.io/4506423100375040',
                integrations: [
                    Sentry.browserTracingIntegration({
                        tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
                    }),
                    Sentry.replayIntegration({
                        networkDetailAllowUrls: [window.location.origin],
                    }),
                ],
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }

        emitter.$on('locale:change', (lang, load = true) => {
            window.locale = lang;

            if (load) {
                loadLanguageAsync(lang);
            }
        });

        emitter.$on('timezone:change', (tz = 'UTC') => {
            window.timezone = tz;
        });

        emitter.$on('loggedIn', ({ id, email }, guard) => {
            if (guard === 'web') {
                initSW();
            }

            Sentry.setUser({ id, email });
        });

        if (user) {
            emitter.$emit('loggedIn', user, guard);
        }

        const inertiaApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueTippy)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                },
            })
            .mount(el);

        emitter.$emit('locale:change', getActiveLanguage(), false);
        emitter.$emit('timezone:change', user?.timezone);

        watch(
            () => usePage().props,
            async (newValue, oldValue) => {
                if (!newValue.auth.user) {
                    return;
                }

                if (newValue.auth.user.lang_id !== oldValue?.auth?.user?.lang_id) {
                    emitter.$emit('locale:change', newValue.auth.user.lang_id);
                }

                if (newValue.auth.user.timezone !== oldValue?.auth?.user?.timezone) {
                    window.timezone = newValue.auth.user.timezone || 'UTC';
                    emitter.$emit('timezone:change', newValue.auth.user.timezone);
                }
            }
        );

        return inertiaApp;
    },
});
