import axios from 'axios';

export function initSW() {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        return;
    }

    navigator.serviceWorker.register('/sw.js').then(() => initPush());
}

function initPush() {
    if (!navigator.serviceWorker.ready) {
        return;
    }

    new Promise((resolve, reject) => {
        Notification.requestPermission(result => resolve(result)).then(resolve, reject);
    }).then(permissionResult => {
        if (permissionResult !== 'granted') {
            return;
        }

        subscribeUser();
    });
}

function subscribeUser() {
    navigator.serviceWorker.ready
        .then(registration => {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC_KEY),
            };

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(pushSubscription => storePushSubscription(pushSubscription));
}

function storePushSubscription(pushSubscription) {
    axios.post(route('notifications.webpush'), pushSubscription);
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}
