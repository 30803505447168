if (typeof Object.groupBy === typeof undefined) {
    Object.groupBy = (arr, callback) => {
        return arr.reduce((acc = {}, ...args) => {
            const key = callback(...args);
            acc[key] ??= [];
            acc[key].push(args[0]);

            return acc;
        }, {});
    };
}
